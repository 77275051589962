import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { FileWordOutlined } from '@ant-design/icons';
import { userActions } from '../../_actions';
import '../HomePage.css';
import { Table } from 'antd';

class GuidesSupport extends Component {
    constructor(props){
        super(props);
        this.state = {
          arrowUp1 : false,
        }
        
        
      }

    render() {
        
        const data = [
            {
                linkName: 'Dealer Guide to Submit Rebates',
                href: "https://sunpowerdocsview.s3.us-west-2.amazonaws.com/Dealer_Guide_to_SunPower_Rebates_Sept_21.docx"
            },
            {
                linkName: 'Homeowner Guide to Submit Rebates',
                href: "https://sunpowerdocsview.s3.us-west-2.amazonaws.com/Homeowner_Guide_to_SunPower_Rebates_Sept_21.docx"
            },
            /*{
                linkName: 'Training Video- Customer submitting a rebate',
                href: "https://sunpowerdocsview.s3.us-west-2.amazonaws.com/Training+Video+-+Customers+submitting+SunPower+rebates.mp4"
            },
            {
                linkName: 'Training Video- Submitting rebates for your customer',
                href: "https://sunpowerdocsview.s3.us-west-2.amazonaws.com/Training+Video+-+Submitting+SunPower+rebates+for+your+customers.mp4"
            },*/
          ];
          const columns = [
            {
              title: 'Name',
              dataIndex: 'linkName',
              key: 'linkName',
              render: (text, record) => <a>{record.linkName}</a>,
            },
            {
              title: 'Action',
              key: 'action',
              render: (text, record) => (
                <span>
                  <a href={record.href} target='_blank' className="blue-link">Download <FileWordOutlined className="align-middle pb-1" /></a>
                </span>
              ),
            },
          ];
        return (

            <Fragment>
                <Container className="pt-5 vh-fill">
                    <Row className="py-md-5">
                        <Col className="pt-5">
                            <h3>Guides & Support</h3>
                        </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Table columns={columns} dataSource={data} />
                      </Col>
                    </Row>
                </Container>
            </Fragment>

        );
    }
}

function mapState(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
}

const actionCreators = {
    getUsers: userActions.getAll,
    deleteUser: userActions.delete
}
export default GuidesSupport;