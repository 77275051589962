import React, { Component, Fragment } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { Container, Row, Col, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarOutlined } from '@ant-design/icons';
import './GeneralReport.css'

class GeneralReport extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false,
          reportType: '',
          columns: [],
          startDate: '',
          endDate: '',
          checkedStatus: false,
          column: {
            fname: false,
            lname: false,
            address1: false,
            address2: false,
            city: false,
            state: false,
            zip: false,
            email: false,
            phone: false,
            promocode: false,
            systemdate: false,
            moduleName: false,
            saletype: false,
            cardType: false,
            dname: false,
            daddress1: false,
            dcity: false,
            dzip: false,
            trackingNo: false,
            dealercode: false,
            amount: false,
            status: false,
            processDate: false,
            mailingaddress1: false,
            mailingaddress2: false,
            mailingcity: false,
            mailingstate: false,
            mailingzip: false,
            contractDate: false
          }
        };
        this.handleChange = this.handleChange.bind(this);
        this.captureStartDate = this.captureStartDate.bind(this);
        this.captureEndDate = this.captureEndDate.bind(this);
        this.captureColumn = this.captureColumn.bind(this);
        this.reportExport = this.reportExport.bind(this);
        this.selectClickCheck = this.selectClickCheck.bind(this);
        this.selectClickUnCheck = this.selectClickUnCheck.bind(this);
      }
    
      toggle(e) {
          console.log(e.target.value);
        /*this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
          }));*/
        this.setState({reportType: e.target.value});
      }

      handleChange(date) {
        this.setState({
          startDate: date
        });
      }

    captureStartDate = date => {
        this.setState({
            startDate: date
        });
    };

    captureEndDate = date => {
        //let enddate = moment(date).format("YYYY-MM-DD");
        this.setState({
            endDate: date
        });
    };

    captureColumn(e) {
        let column = this.state.column;
        if (e.target.checked) {
            let columnSelected = [];
            if (this.state !== undefined) {
                columnSelected= this.state.columns;
            }
            column[e.target.value] = true;
            console.log(columnSelected)
            columnSelected.push(e.target.value);
            this.setState({columns: columnSelected, column: column});
        } else {
            let lastCols= this.state.columns;
            let index = lastCols.indexOf(e.target.value);
            if (index > -1) {
                lastCols.splice(index, 1);
            }
            column[e.target.value] = false;
            this.setState({columns: lastCols, column: column});
        }
    }

    selectClickCheck()  {
        let columnSelected = [];
        columnSelected.push('fname');
        columnSelected.push('lname');
        columnSelected.push('address1');
        columnSelected.push('address2');
        columnSelected.push('city');
        columnSelected.push('state');
        columnSelected.push('zip');
        columnSelected.push('mailingaddress1');
        columnSelected.push('mailingaddress2');
        columnSelected.push('mailingcity');
        columnSelected.push('mailingstate');
        columnSelected.push('mailingzip');
        columnSelected.push('email');
        columnSelected.push('phone');
        columnSelected.push('promocode');
        columnSelected.push('systemdate');
        columnSelected.push('contractDate');
        columnSelected.push('moduleName');
        columnSelected.push('saletype');
        columnSelected.push('cardType');
        columnSelected.push('dname');
        columnSelected.push('daddress1');
        columnSelected.push('dcity');
        columnSelected.push('dzip');
        columnSelected.push('trackingNo');
        columnSelected.push('dealercode');
        columnSelected.push('amount');
        columnSelected.push('status');
        columnSelected.push('processDate');
        
        let column = {
            fname: true,
            lname: true,
            address1: true,
            address2: true,
            city: true,
            state: true,
            zip: true,
            mailingaddress1: true,
            mailingaddress2: true,
            mailingcity: true,
            mailingstate: true,
            mailingzip: true,
            email: true,
            phone: true,
            promocode: true,
            systemdate: true,
            contractDate: true,
            moduleName: true,
            saletype: true,
            cardType: true,
            dname: true,
            daddress1: true,
            dcity: true,
            dzip: true,
            trackingNo: true,
            dealercode: true,
            amount: true,
            status: true,
            processDate: true
          };
        this.setState({checkedStatus : true, columns: columnSelected, column: column});
    }

    selectClickUnCheck() {
        const columnSelected = [];
        let column = {
            fname: false,
            lname: false,
            address1: false,
            address2: false,
            city: false,
            state: false,
            zip: false,
            mailingaddress1: false,
            mailingaddress2: false,
            mailingcity: false,
            mailingstate: false,
            mailingzip: false,
            email: false,
            phone: false,
            promocode: false,
            systemdate: false,
            contractDate: false,
            moduleName: false,
            saletype: false,
            cardType: false,
            dname: false,
            daddress1: false,
            dcity: false,
            dzip: false,
            trackingNo: false,
            dealercode: false,
            amount: false,
            status: false,
            processDate: false
          }
        this.setState({checkedStatus : false, columns: columnSelected, column: column});
    }

    reportExport() {
        console.log(this.state.columns.toString());
        console.log(this.state.endDate.toLocaleDateString());
        console.log(this.state.startDate.toLocaleDateString());
        console.log(this.state.reportType);
        const userVar = JSON.parse(localStorage.getItem('user'));
        let reportUrl = process.env.REACT_APP_BACKEND_URL+"/reportexport.php?reportType="+this.state.reportType+
            "&startDate="+this.state.startDate.toLocaleDateString()+"&endDate="+this.state.endDate.toLocaleDateString()+"&columns="+this.state.columns.toString()+"&userVar="+userVar.token;
        console.log(reportUrl);
        window.open(reportUrl, "_blank");
    }

      render() {
        return (
            <Fragment>
                <Container className="py-5 vh-fill">
                    <Row className="pt-md-5">
                        <Col className="pt-5">
                        <h3>Reports</h3>
                        <p>Reports will be produced and downloaded in Excel.</p>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label className="pt-4">Report Type:</Form.Label>
                                    <select className="form-select" onChange={this.toggle}>
                                        <option selected disabled>Select report type</option>
                                        <option value="claims">Rebate Report</option>
                                    </select>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label className="d-block pt-4">Start Date:</Form.Label>
                                <DatePicker
                                    id='date-picker'
                                    placeholderText='mm/dd/yyyy'
                                    selected={this.state.startDate}
                                    onChange={this.captureStartDate}
                                    dateFormat="MM-dd-yyyy"
                                    className="form-control"
                                />
                                <CalendarOutlined className="date-icon" />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label className="d-block pt-4">End Date:</Form.Label>
                                    <DatePicker
                                    id='date-picker'
                                    placeholderText='mm/dd/yyyy'
                                    selected={this.state.endDate}
                                    onChange={this.captureEndDate}
                                    dateFormat="MM-dd-yyyy"
                                    className="form-control"
                                />
                                <CalendarOutlined className="date-icon" />
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row className="py-4 border-top">
                        <Col>
                            <Button onClick={this.selectClickCheck} className="btn-sm btn-gray-secondary me-1">Select All</Button>
                            <Button onClick={this.selectClickUnCheck} className="btn-sm btn-gray-secondary ms-1">Deselect All</Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-between pb-4 border-bottom">
                        <Col md={2} xs={6}>
                            <Form.Group controlId="fname">
                                <Form.Check type="checkbox" name="fname" value="fname" label="First Name" onChange={this.captureColumn} checked={this.state.column.fname}/>
                            </Form.Group>
                            <Form.Group controlId="lname">
                                <Form.Check type="checkbox" name="lname" value="lname" label="Last Name" onChange={this.captureColumn} checked={this.state.column.lname} />
                            </Form.Group>
                            <Form.Group controlId="address1">
                                <Form.Check type="checkbox" name="address1" value="address1" label="Address 1" onChange={this.captureColumn} checked={this.state.column.address1} />
                            </Form.Group>
                            <Form.Group controlId="address2">
                                <Form.Check type="checkbox" name="address2" value="address2" label="Address 2" onChange={this.captureColumn} checked={this.state.column.address2} />
                            </Form.Group>
                            <Form.Group controlId="city">
                                <Form.Check type="checkbox" name="city" value="city" label="City" onChange={this.captureColumn} checked={this.state.column.city} />
                            </Form.Group>
                            <Form.Group controlId="state">
                                <Form.Check type="checkbox" name="state" value="state" label="State" onChange={this.captureColumn} checked={this.state.column.state} />
                            </Form.Group>
                        </Col>
                        <Col md={2} xs={6}>
                            <Form.Group controlId="zip">
                                <Form.Check type="checkbox" name="zip" value="zip" label="Zip" onChange={this.captureColumn} checked={this.state.column.zip} />
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Check type="checkbox" name="email" value="email" label="Email" onChange={this.captureColumn} checked={this.state.column.email} />
                            </Form.Group>
                            <Form.Group controlId="phone">
                                <Form.Check type="checkbox" name="phone" value="phone" label="Phone" onChange={this.captureColumn} checked={this.state.column.phone} />
                            </Form.Group>
                            <Form.Group controlId="mailingaddress1">
                                <Form.Check type="checkbox" name="mailingaddress1" value="mailingaddress1" label="Mailing Address 1" onChange={this.captureColumn} checked={this.state.column.mailingaddress1} />
                            </Form.Group>
                            <Form.Group controlId="mailingaddress2">
                                <Form.Check type="checkbox" name="mailingaddress2" value="mailingaddress2" label="Mailing Address 2" onChange={this.captureColumn} checked={this.state.column.mailingaddress2} />
                            </Form.Group>
                            <Form.Group controlId="mailingcity">
                                <Form.Check type="checkbox" name="mailingcity" value="mailingcity" label="Mailing City" onChange={this.captureColumn} checked={this.state.column.mailingcity} />
                            </Form.Group>
                        </Col>
                        <Col md={2} xs={6}>
                            <Form.Group controlId="mailingstate">
                                <Form.Check type="checkbox" name="mailingstate" value="mailingstate" label="Mailing State" onChange={this.captureColumn} checked={this.state.column.mailingstate} />
                            </Form.Group>
                            <Form.Group controlId="mailingzip">
                                <Form.Check type="checkbox" name="mailingzip" value="mailingzip" label="Mailing Zip" onChange={this.captureColumn} checked={this.state.column.mailingzip} />
                            </Form.Group>
                            <Form.Group controlId="dname">
                                <Form.Check type="checkbox" name="dname" value="dname" label="Dealer Name" onChange={this.captureColumn} checked={this.state.column.dname} />
                            </Form.Group>
                            <Form.Group controlId="dealercode">
                                <Form.Check type="checkbox" name="dealercode" value="dealercode" label="Dealer ID" onChange={this.captureColumn} checked={this.state.column.dealercode} />
                            </Form.Group>
                            <Form.Group controlId="daddress1">
                                <Form.Check type="checkbox" name="daddress1" value="daddress1" label="Dealer Address" onChange={this.captureColumn} checked={this.state.column.daddress1} />
                            </Form.Group>
                            <Form.Group controlId="dcity">
                                <Form.Check type="checkbox" name="dcity" value="dcity" label="Dealer City" onChange={this.captureColumn} checked={this.state.column.dcity} />
                            </Form.Group>
                        </Col>
                        <Col md={2} xs={6}>
                            <Form.Group controlId="dzip">
                                <Form.Check type="checkbox" name="dzip" value="dzip" label="Dealer Zip" onChange={this.captureColumn} checked={this.state.column.dzip} />
                            </Form.Group>
                            <Form.Group controlId="trackingNo">
                                <Form.Check type="checkbox" name="trackingNo" value="trackingNo" label="Tracking ID" onChange={this.captureColumn} checked={this.state.column.trackingNo} />
                            </Form.Group>
                            <Form.Group controlId="status">
                                <Form.Check type="checkbox" name="status" value="status" label="Rebate Status" onChange={this.captureColumn} checked={this.state.column.status} />
                            </Form.Group>
                            <Form.Group controlId="promocode">
                                <Form.Check type="checkbox" name="promocode" value="promocode" label="Rebate Code" onChange={this.captureColumn} checked={this.state.column.promocode} />
                            </Form.Group>
                            <Form.Group controlId="systemdate">
                                <Form.Check type="checkbox" name="systemdate" value="systemdate" label="Install Date" onChange={this.captureColumn} checked={this.state.column.systemdate} />
                            </Form.Group>
                            <Form.Group controlId="contractDate">
                                <Form.Check type="checkbox" name="contractDate" value="contractDate" label="Contract Date" onChange={this.captureColumn} checked={this.state.column.contractDate} />
                            </Form.Group>
                        </Col>
                        <Col md={2} xs={6}>
                        <Form.Group controlId="moduleName">
                                <Form.Check type="checkbox" name="moduleName" value="moduleName" label="Module" onChange={this.captureColumn} checked={this.state.column.moduleName} />
                            </Form.Group>
                            <Form.Group controlId="saletype">
                                <Form.Check type="checkbox" name="saletype" value="saletype" label="Sale Type" onChange={this.captureColumn} checked={this.state.column.saletype} />
                            </Form.Group>
                        <Form.Group controlId="cardType">
                                <Form.Check type="checkbox" name="cardType" value="cardType" label="Reward Type" onChange={this.captureColumn} checked={this.state.column.cardType} />
                            </Form.Group>
                            <Form.Group controlId="amount">
                                <Form.Check type="checkbox" name="amount" value="amount" label="Rebate Amount" onChange={this.captureColumn} checked={this.state.column.amount} />
                            </Form.Group>
                            <Form.Group controlId="processDate">
                                <Form.Check type="checkbox" name="processDate" value="processDate" label="Approval/Decline Date" onChange={this.captureColumn} checked={this.state.column.processDate} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col>
                        <Button type="button" className="btn-orange" onClick={this.reportExport}>Export Report</Button>
                        </Col>
                    </Row>
                </Container>
            </Fragment>

        );
      }
}

export default GeneralReport;