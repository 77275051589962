import React, { Component } from 'react';
import axios from 'axios';
import { Container, Col, Row, ListGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import './UploadFile.css'

let HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

class UploadFile extends Component {
  constructor(props){
    super(props);
    this.state = {
      success : false,
      url : "",
      reportType: '',
      formData : '',
      templateMessage: false,
      spinner: false
    }
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    //fontawesome.library.add(faSpinner);
  }
  
  toggle(e) {
    console.log(e.target.value);
  /*this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));*/
    this.setState({reportType: e.target.value});
  }

  handleChange = (ev) => {
      console.log(ev.target.files[0]);
    let fileParts = ev.target.files[0].name.split('.');
    let fileName = fileParts[0];
    let fileType = fileParts[1];
    let formData  = new FormData();
    formData.append("userfile", ev.target.files[0]);
    formData.append("fileType", fileType);
    formData.append("fileName", fileName);
    formData.append("reportType", this.state.reportType);
    const userVar = JSON.stringify(localStorage.getItem('user'));
    formData.append("userVar", userVar);
    this.setState({success: false, url : "", formData: formData});
    
  }
  // Perform the upload
  handleUpload = (ev) => {
    /*let file = this.uploadInput.files[0];
    // Split the filename to get the name and type
    let fileParts = this.uploadInput.files[0].name.split('.');
    let fileName = fileParts[0];
    let fileType = fileParts[1];
    let formData = new FormData();
    console.log(ev.target);
    /*formData.append("userfile", ev.target.files[0]);
    formData.append("fileType", fileType);
    formData.append("fileName", fileName);
    formData.append("reportType", this.state.reportType);*/
    console.log("Preparing the upload");
    let url = 'uploaddealerreg.php';
    this.setState({spinner: true});
    axios.post(process.env.REACT_APP_BACKEND_URL+"/"+url,this.state.formData)
    .then(response => {
        console.log(response);
        this.setState({success: response.data.status, templateMessage: response.data.error, spinner: false});
        if (response.data.documentUpload) {
          window.location.href = '/uploaddocs';
        }
      //var returnData = response.data.data.returnData;
      //var signedRequest = returnData.signedRequest;
      //var url = returnData.url;
      //this.setState({url: url})
      //console.log("Recieved a signed request " + signedRequest);
      
     // Put the fileType in the headers for the upload
      /*var options = {
        headers: {
          'Content-Type': fileType
        }
      };
      /*axios.put(signedRequest,file,options)
      .then(result => {
        console.log("Response from s3")
        this.setState({success: true});
      })
      .catch(error => {
        alert("ERROR " + JSON.stringify(error));
      })*/
    })
    .catch(error => {
      alert(JSON.stringify(error));
    })
  }
  
  
  render() {
    const Success_message = () => (
        <div>
            <div className="alert alert-success">SUCCESSFUL UPLOAD</div>
            <br/>
        </div>
    )
    const Error_message = () => (
      <div>
          <div className="alert alert-danger">
            {htmlToReactParser.parse(this.state.templateMessage)}
          </div>
          <br/>
      </div>
  )
    return (
        
      <Container className="py-5 vh-fill">
        <Row className="py-md-5">
          <Col className="pt-5">
              <h3>Upload Rebates</h3>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="pe-md-5">
            <ol className="fs-5 fw-bold">
              <li className="py-4 border-bottom"><span className="d-block pb-2">Download the rebate template</span>
              <a type="button" href="https://sunpowerrebate.s3-us-west-1.amazonaws.com/Rebate-Upload-Template.xlsx" target="_blank" className="btn btn-sm btn-outline-secondary"><FontAwesomeIcon icon={faFileExcel} className="fs-5 me-2" />Rebate Template</a>
              <span className="fs-6 d-block py-2 fw-light"> This template was updated as of 09/22/2021</span></li>
              <li className="py-4 border-bottom">Enter your customer's rebate data in the template</li>
              <li className="py-4">Upload the completed template below
              {this.state.spinner ? <FontAwesomeIcon icon={faSpinner} transform="grow-7 left-1.5 up-2.2" spin /> : null}
                {this.state.success ? <Success_message/> : null}
                {this.state.templateMessage ? <Error_message/> : null}
                <input onChange={this.handleChange} ref={(ref) => { this.uploadInput = ref; }} type="file" className="form-control my-4" />
                <Button type="button" className="btn-orange mx-auto" onClick={this.handleUpload}>Upload File</Button>
                </li>
            </ol>
            
          </Col>
          <Col md={6} className="bg-light p-4">
                <p>Please note the new requirements:</p> 
                <p className="fw-bold text-decoration-underline mb-2">Seasonal Rebate Codes:</p>
                <ul>
                    <li>Signed contract required</li>
                    <li>Dealer Invoice not accepted</li>
                </ul>
                <p className="fw-bold text-decoration-underline mb-2">Friends & Family Rebate Codes:</p>
                <ul>
                  <li>SunPower Employee First Name, Last Name, Email & Phone are mandatory. (columns W-Z on the excel template)</li>
                  <li>The SunPower Employee's email address must end in "@sunpower.com"</li>
                </ul>
                <p className="fw-bold text-decoration-underline mb-2">Rebate Registration</p>
                <ul>
                  <li>Tracking Number is mandatory when completing a rebate request.</li>
                  <li>Customer First Name, Last Name and Install Address cannot be changed when completing a rebate request.</li>
                </ul>
          </Col>
        </Row>
      </Container>
      
    );
  }
}
export default UploadFile;