import React, {Fragment} from 'react';
import { Row, Col, Container, Card, Form, Alert, Collapse } from 'react-bootstrap';

import { CalendarOutlined } from '@ant-design/icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Dashboard.css';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area, Bar, BarChart } from 'recharts';



const data = [
    {
      name: 'Jan',
      uv: 1400,
      pv: 1400,
      amt: 2400,
    },
    {
      name: 'Feb',
      uv: 2000,
      pv: 2000,
      amt: 2210,
    },
    {
      name: 'Mar',
      uv: 1800,
      pv: 1800,
      amt: 2290,
    },
    {
      name: 'Apr',
      uv: 1600,
      pv: 1600,
      amt: 2000,
    },
    {
        name: 'May',
        uv: 2000,
        pv: 2000,
        amt: 2000,
      },
      {
        name: 'Jun',
        uv: 2780,
        pv: 2780,
        amt: 1400,
      },
      {
        name: 'Jul',
        uv: 2400,
        pv: 2400,
        amt: 1800,
      },
      {
        name: 'Aug',
        uv: 2000,
        pv: 2000,
        amt: 2000,
      },
      {
        name: 'Sept',
        uv: 2200,
        pv: 2200,
        amt: 1000,
      },
      {
        name: 'Oct',
        uv: 2600,
        pv: 2600,
        amt: 1600,
      },
      {
        name: 'Nov',
        uv: 1600,
        pv: 1600,
        amt: 2400,
      },
      {
        name: 'Dec',
        uv: 1800,
        pv: 1800,
        amt: 2200,
      },
  ];

  
const DashboardContent = () => {

    return (
        <Container className="py-5 vh-fill">
            <Row className="pt-md-5">
              <Col className="pt-5">
                <h3 className="pb-4">Welcome, Anna</h3>
              <Alert className="alert alert-light bg-sp-blue text-white shadow-sm p-4">
                    <h5 className="text-white">Update your closed sales</h5>
                    <p className="mb-0">Please visit the <a href="https://sunpower1.force.com/signin" target="_blank" className="blue-link text-white">SunPower Portal</a> to report your closed sales.</p>
                </Alert>              
              </Col>
            </Row>
            <Row className="gy-4">
                <Col lg={6}>
                    <Card className="border p-4">
                        <Row className="justify-content-between">
                            <Col sm={6}>
                                <h5 className="text-secondary">Total # of Rebates</h5>
                                <h6>Approved/Processed</h6>
                                <h2 className="sp-green fs-1">24</h2>
                            </Col>
                            <Col sm={3} xs={6}>
                                <Form.Group>
                                    <Form.Label>Year:</Form.Label>
                                        <select className="form-select form-select-sm">
                                            <option>2019</option>
                                            <option>2020</option>
                                            <option selected>2021</option>
                                            <option>2022</option>
                                        </select>
                                </Form.Group>
                            </Col>
                            <Col sm={3} xs={6}>
                                <Form.Group>
                                    <Form.Label>Month:</Form.Label>
                                        <select className="form-select form-select-sm">
                                            <option>Entire Year</option>
                                            <option>January</option>
                                            <option>February</option>
                                            <option>March</option>
                                            <option>April</option>
                                            <option>May</option>
                                            <option>June</option>
                                            <option>July</option>
                                            <option>August</option>
                                            <option>September</option>
                                            <option>October</option>
                                            <option>November</option>
                                            <option>December</option>
                                        </select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="pt-5">
                            <Col>
                            <div style={{ width: '100%', height: 300 }}>
                            <ResponsiveContainer>
                            <LineChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                <Line type="monotone" dataKey="uv" stroke="#F79320" />
                                <CartesianGrid stroke="#eee" />
                                <XAxis dataKey="name" />
                                <YAxis padding={{ top: 30, bottom: 30 }} />
                                <Tooltip />
                            </LineChart>
                            </ResponsiveContainer>
                            </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card className="p-4">
                        <Row className="justify-content-between">
                            <Col sm={6}>
                                <h5 className="text-secondary">Total $ Amount</h5>
                                <h6>Approved/Processed</h6>
                                <h2 className="sp-green fs-1">$9,238.34</h2>
                            </Col>
                            <Col sm={3} xs={6}>
                                <Form.Group>
                                    <Form.Label>Year:</Form.Label>
                                        <select className="form-select form-select-sm">
                                            <option>2019</option>
                                            <option>2020</option>
                                            <option selected>2021</option>
                                            <option>2022</option>
                                        </select>
                                </Form.Group>
                            </Col>
                            <Col sm={3} xs={6}>
                                <Form.Group>
                                    <Form.Label>Month:</Form.Label>
                                        <select className="form-select form-select-sm">
                                            <option>Entire Year</option>
                                            <option>January</option>
                                            <option>February</option>
                                            <option>March</option>
                                            <option>April</option>
                                            <option>May</option>
                                            <option>June</option>
                                            <option>July</option>
                                            <option>August</option>
                                            <option>September</option>
                                            <option>October</option>
                                            <option>November</option>
                                            <option>December</option>
                                        </select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="pt-5">
                            <Col>
                                <div style={{ width: '100%', height: 300 }}>
                                <ResponsiveContainer>
                                <LineChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <Line type="monotone" dataKey="uv" stroke="#F79320" />
                                    <CartesianGrid stroke="#eee" />
                                    <XAxis dataKey="name" />
                                    <YAxis padding={{ top: 30, bottom: 30 }} />
                                    <Tooltip />
                                </LineChart>
                                </ResponsiveContainer>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={12}>
                    <Card className="p-4">
                        <Row className="justify-content-between align-items-end">
                            <Col sm={6}>
                                <h5 className="text-secondary">Total Rebates</h5>
                            </Col>
                            <Col sm={3} xs={6}>
                                <Form.Group>
                                    <Form.Label>Year:</Form.Label>
                                        <select className="form-select form-select-sm">
                                            <option>2019</option>
                                            <option>2020</option>
                                            <option selected>2021</option>
                                            <option>2022</option>
                                        </select>
                                </Form.Group>
                            </Col>
                            <Col sm={3} xs={6}>
                                <Form.Group>
                                    <Form.Label>Month:</Form.Label>
                                        <select className="form-select form-select-sm">
                                            <option>Entire Year</option>
                                            <option>January</option>
                                            <option>February</option>
                                            <option>March</option>
                                            <option>April</option>
                                            <option>May</option>
                                            <option>June</option>
                                            <option>July</option>
                                            <option>August</option>
                                            <option>September</option>
                                            <option>October</option>
                                            <option>November</option>
                                            <option>December</option>
                                        </select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="pt-5 text-center sp-orange justify-content-evenly">
                            <Col>
                                <h6>Processed</h6>
                                <h2 className="sp-green fs-1">58</h2>
                            </Col>
                            <Col md={2} sm={3} xs={6} className="pb-4">
                                <h6>Registered</h6>
                                <h2 className="sp-green fs-1">32</h2>
                            </Col>
                            <Col md={2} sm={3} xs={6} className="pb-4">
                                <h6>Pending</h6>
                                <h2 className="sp-green fs-1">2</h2>
                            </Col>
                            <Col md={2} sm={3} xs={6}>
                                <h6>Approved</h6>
                                <h2 className="sp-green fs-1">48</h2>
                            </Col>
                            <Col md={2} sm={3} xs={6}>
                                <h6>Declined</h6>
                                <h2 className="sp-green fs-1">8</h2>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
export default DashboardContent;
