import React, { Component, Fragment } from 'react';
import 'antd/dist/antd.css';
import { Table, Button } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';

class DocUploadTable extends Component {
    constructor(props) {
      super(props);
      this.state = {
        tableData: [],
        formData : new FormData(),
        ids: [],
        spinnerDisplay: false,
        spinnerText: '',
        successMessage: false,
        rebateCount: 0
      }
      
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }  

    componentDidMount() {
        this.renderList();
    }

    renderList() {
        
          const userVar = JSON.parse(localStorage.getItem('user'));
          let tableData = [];
          this.setState({spinnerDisplay: true, spinnerText: 'Searching for rebates pending document upload...'});
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rebatedocumentupload`, {
            headers: {
                "Authorization" : `Bearer ${userVar.token2}`,
                'Accept' : 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
            }})
            .then(response => {
                console.log(response);
                let rCount = this.state.rebateCount;
                response.data.map(entry => {
                    tableData.push(entry);
                    rCount++;
                    
                })
                //this.setState({ data: tableData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
                console.log(tableData);
                this.setState({tableData, spinnerDisplay: false, spinnerText: '', rebateCount: rCount});
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        localStorage.clear();
                        window.location.href = '/login';
                    }
                    /*console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);*/
                }
            });
        
    }

    handleChange(ev, id) {
        console.log(ev.target);
        console.log(id);
        let formDataTemp = this.state.formData;
        let idsTemp = this.state.ids;
        if (ev.target.files[0]) {
            
            let fileParts = ev.target.files[0].name.split('.');
            let fileName = fileParts[0];
            let fileType = fileParts[1];
            formDataTemp.append("userfile_"+id, ev.target.files[0]);
            formDataTemp.append("fileType_"+id, fileType);
            formDataTemp.append("fileName_"+id, fileName);
            idsTemp.push(id);
            this.setState({formData: formDataTemp, ids: idsTemp});
            //console.log('id: ',id);
        } else {
            formDataTemp.delete("userfile_"+id);
            formDataTemp.delete("fileType_"+id);
            formDataTemp.delete("fileName_"+id);
            let idList = [];
            idsTemp.map(idEntry => {
                if (idEntry != id) {
                    idList.push(idEntry);
                }
            });
            this.setState({formData: formDataTemp, ids: idList});
        }
    }

    handleSubmit(e) {
        let formDataTemp = this.state.formData;
        formDataTemp.append('userIds', JSON.stringify(this.state.ids));
        const userVar = JSON.parse(localStorage.getItem('user'));
        let tableData = [];
        this.setState({spinnerDisplay: true, spinnerText: 'Attaching documents to rebates...'});
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/rebatedocumentupload`, formDataTemp, {
            headers: {
                "Authorization" : `Bearer ${userVar.token2}`
            }})
        .then(response => {
            console.log(response);
            let rCount = 0
            response.data.map(entry => {
                tableData.push(entry)
                rCount++;
            })
            console.log(rCount,':',this.state.rebateCount);
            let successMessage = (rCount < this.state.rebateCount) ? true : false;
            this.setState({tableData, spinnerDisplay: false, spinnerText: '', rebateCount: rCount, successMessage});
        }).catch(error => {
            if (error.response) {
                if (error.response.data.error == "token_expired") {
                    localStorage.clear();
                    window.location.href = '/login';
                }
                /*console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);*/
              }
        });
        
    }

    render() {
        const columns = [
        {
          title: 'First Name',
          dataIndex: 'fname',
          key: 'fname',
        },
        {
          title: 'Last Name',
          dataIndex: 'lname',
          key: 'lname',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'File',
          key: 'fupload',
          dataIndex: 'fupload',
          render: (text, record) => (
            <span>
              <input onChange={(e) => {this.handleChange(e, record.id)}} type="file" className="form-control" />
            </span>
          ),
        }
        ];

        const Success_message = () => (
            <div>
                <div className="alert alert-success">This document has been succesfully uploaded and is pending being reviewed by SunPower. This can take up to approximatly 5-7 days to receive SunPower approval or notes or comments.</div>
                <br/>
            </div>
        )
    
        return (
            <LoadingOverlay
                active={this.state.spinnerDisplay}
                spinner
                text={this.state.spinnerText}
                >
            <Fragment>
            <Container className="py-5 vh-fill">
                  <Row className="py-md-5">
                    <Col className="pt-5">
                      <h3>Upload Documents</h3>
                      <p>Dealer Installation Agreement/Contract is required (This is not the invoice. This is the contract/agreement) for rebate approval.</p>
                    </Col>
                  </Row>
                    <Row>
                        <Col>
                            {this.state.successMessage ? <Success_message/> : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table columns={columns} dataSource={this.state.tableData} />
                            <Button className="btn-orange" onClick={(e) => {this.handleSubmit(e)}}>Submit</Button>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
            </LoadingOverlay>
        );
    }
}

export default DocUploadTable;
