import React from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { HomePage } from '../HomePage';
import { LoginPage } from '../LoginPage';
import { RegisterPage } from '../RegisterPage';
import { Reports } from '../HomePage/account/Reports';
import { Logout } from '../HomePage/account/Logout';
import { ForgotPage } from '../forgot/ForgotPage';
import { PasswordResetPage } from '../HomePage/PasswordReset';
import { FileUploader } from '../HomePage/account/FileUploader';
import { ContactPage } from '../HomePage/account/ContactPage';
import { Faqs } from '../HomePage/account/Faqs';
import { AddUsers } from '../HomePage/account/AddUsers';
import { ListUsers } from '../HomePage/account/ListUsers';
import { DocUpload } from '../HomePage/account/DocUpload';
import { SeasonalPromo } from '../HomePage/account/SeasonalPromo';
import {RebateTableNav} from '../HomePage/account/RebateTableNav';
import {GuidesSupportNav} from '../HomePage/account/GuidesSupportNav';
import Dashboard from '../HomePage/account/Dashboard';

class App extends React.Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
            this.props.clearAlerts();
        });
    }

    render() {
        const { alert } = this.props;
        return (

            <Router history={history}>
                <div>
                    <PrivateRoute exact path="/" component={Dashboard} />
                    <PrivateRoute exact path="/submittedrebates" component={RebateTableNav} />
                    <PrivateRoute exact path="/reports" component={Reports} />
                    <PrivateRoute exact path="/upload" component={FileUploader} />
                    <PrivateRoute exact path="/contactus" component={ContactPage} />
                    <PrivateRoute exact path="/faqs" component={Faqs} />
                    <PrivateRoute exact path="/logout" component={Logout} />
                    <PrivateRoute exact path="/adduser" component={AddUsers} />
                    <PrivateRoute exact path="/listusers" component={ListUsers} />
                    <PrivateRoute exact path="/passwordreset" component={PasswordResetPage} />
                    <PrivateRoute exact path="/uploaddocs" component={DocUpload} />
                    <PrivateRoute exact path="/seasonal" component={SeasonalPromo} />
                    <PrivateRoute exact path="/guidesupport" component={GuidesSupportNav} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/forgot" component={ForgotPage} />
                    <Route path="/register" component={RegisterPage} />
                </div>
            </Router>

        );
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };