import React, { Component, Fragment } from 'react';
import 'antd/dist/antd.css';
import { Table, Button } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import { FilePdfOutlined } from '@ant-design/icons';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';

class SeasonalPromotion extends Component {
    constructor(props) {
      super(props);
      this.state = {
        tableData: [],
        formData : new FormData(),
        ids: [],
        spinnerDisplay: false,
        spinnerText: '',
        successMessage: false,
        rebateCount: 0
      }
      
    }  

    componentDidMount() {
        this.renderList();
    }

    renderList() {
        
          const userVar = JSON.parse(localStorage.getItem('user'));
          let tableData = [];
          this.setState({spinnerDisplay: true, spinnerText: 'Searching for seasonal promotions...'});
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/seasonalpromotions`, {
            headers: {
                "Authorization" : `Bearer ${userVar.token2}`,
                'Accept' : 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
            }})
            .then(response => {
                console.log(response);
                let rCount = this.state.rebateCount;
                response.data.map(entry => {
                    tableData.push(entry);
                    rCount++;
                    
                })
                //this.setState({ data: tableData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
                console.log(tableData);
                this.setState({tableData, spinnerDisplay: false, spinnerText: '', rebateCount: rCount});
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        localStorage.clear();
                        window.location.href = '/login';
                    }
                    /*console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);*/
                }
            });
        
    }

    render() {
        const columns = [
        {
          title: 'Rebate Code',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Start Date',
          dataIndex: 'startDate',
          key: 'startDate',
        },
        {
          title: 'End Date',
          dataIndex: 'endDate',
          key: 'endDate',
        },
        {
          title: 'Download',
          key: 'pdfpath',
          dataIndex: 'pdfpath',
          render: (text, record) => (
            <span>
              <a href={record.pdfpath} className="blue-link" target='_blank'>Download <FilePdfOutlined className="align-middle pb-1" /></a>
            </span>
          ),
        }
        ];
    
        return (
            <LoadingOverlay
                active={this.state.spinnerDisplay}
                spinner
                text={this.state.spinnerText}
                >
            <Fragment>
                <Container className="py-5 vh-fill">
                  <Row className="py-md-5">
                    <Col className="pt-5">
                      <h3>Seasonal Promotions</h3>
                    </Col>
                  </Row>
                    <Row>
                        <Col>
                        <Table columns={columns} dataSource={this.state.tableData} scroll={{ y: 440 }} />
                        </Col>
                    </Row>
                </Container>
            </Fragment>
            </LoadingOverlay>
        );
    }
}

export default SeasonalPromotion;
